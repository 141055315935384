<template>
  <div>
    <div v-if="loading" class="loading-container pt-12">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </div>
    <v-form
      @submit.prevent="save"
      ref="form"
      v-show="!loading"
      :disabled="$attrs.disabled"
    >
      <ErrorMessages :messages="errors"></ErrorMessages>
      <v-row>
        <v-col>
          <v-text-field
            v-model="form.description"
            :rules="$rules.required"
            label="Descrição"
            required
            dense
            outlined
          ></v-text-field>
        </v-col>
        <v-col>
          <v-select
            label="Evento"
            :items="events"
            v-model="form.event"
            item-value="value"
            item-text="label"
            :rules="$rules.required"
            required
            dense
            outlined
          ></v-select>
        </v-col>
        <v-col>
          <v-select
            label="Caixa de saída"
            :items="outboxes"
            v-model="form.outbox_id"
            item-value="id"
            item-text="name"
            :rules="$rules.required"
            required
            dense
            outlined
          ></v-select>
        </v-col>
      </v-row>

      <v-row class="mb-4">
        <v-col cols="8">
          <v-tabs class="nj-tabs" v-model="tab">
            <v-tab>Corpo</v-tab>
          </v-tabs>
          <v-tabs-items v-model="tab" eager>
            <v-tab-item class="py-4" eager>
              <nj-editor
                :disabled="$attrs.disabled"
                ref="bodyEditor"
                :custom-events="customEditorEvents"
                v-model="form.body"
              >
              </nj-editor>
            </v-tab-item>
          </v-tabs-items>
        </v-col>
        <v-col cols="4" class="font-14">
          <p>Parâmetros disponíves:</p>
          <div v-for="(param, index) in params" :key="index">
            <div v-text="`{{${param}}}`"></div>
          </div>
        </v-col>
      </v-row>

      <slot></slot>
    </v-form>
  </div>
</template>
<style scoped>
.max-width {
  max-width: 600px;
}
</style>
<script>
import Api from '@/resources/NajaSocialApi'
import EditorUpload from '@/resources/editorUpload'
import ErrorMessages from '@/components/ErrorMessages'
const PARAMS = [
  'NUMERO_EXAME',
  'DATA_EXAME',
  'HORA_EXAME',
  'NOME_PACIENTE',
  'PRIMEIRO_NOME_PACIENTE',
  'SENHA',
  'LOGIN',
  'CODIGO_CONVENIO',
  'NOME_CONVENIO',
  'CRM_MEDICO',
  'NOME_MEDICO',
  'NUMERO_PRODUTO',
  'NOME_PRODUTO',
  'NOME_REDUZIDO_PRODUTO',
  'PREVISAO_DE_ENTREGA',
  'TELEFONE',
  'EMAIL',
  'CODIGO_EMPRESA',
  'NOME_EMPRESA',
]

export default {
  name: 'NotificationSettingForm',
  components: { ErrorMessages },
  data: () => ({
    saveLoading: false,
    loading: false,
    tab: 0,
    errors: [],
    events: [],
    outboxes: [],
    params: PARAMS,
    customEditorEvents: {
      'image.beforeUpload': EditorUpload.beforeUpload,
    },
    form: {
      description: null,
      event: null,
      outbox_id: null,
      body: null,
    },
  }),

  mounted() {
    this.fetch(this.$route.params.id)
  },

  methods: {
    validate(field) {
      const errorMsg =
        this.errors[field] == null ? false : this.errors[field].join(',')
      if (errorMsg) {
        return [value => !!value || errorMsg]
      }

      return []
    },

    remove() {
      this.$refs.removeQuestionDialog.open({
        onConfirm: () => {
          this.$refs.removeQuestionDialog.toggleLoading()
          Api.roles
            .destroy(this.$route.params.id)
            .then(async () => {
              this.$router.push({ name: 'NotificationSettings' })
            })
            .catch(error => {
              if (error.response) {
                if (error.response.status == 500) {
                  this.$root.$children[0].toast(
                    'Erro ao remover configuração de notificação',
                    'error'
                  )
                } else {
                  this.$root.$children[0].toast(
                    error.response.data.error,
                    'error'
                  )
                }
              } else {
                throw error
              }
            })
            .finally(() => {
              this.$refs.removeQuestionDialog.close()
            })
        },
      })
    },

    _parseForm(data) {
      const formPayload = {
        id: data.id,
        description: data.description,
        event: data.event,
        outbox_id: data.outbox_id,
        body: data.body,
      }

      this.form = { ...this.form, ...formPayload }
    },

    async fetch(id = null) {
      try {
        this.loading = true

        const eventsResult = await Api.notificationSettings.events()
        this.events = eventsResult.data

        const outboxesResult = await Api.outboxes.search()
        this.outboxes = outboxesResult.data.outboxes

        if (id === null) {
          return
        }

        const response = await Api.notificationSettings.fetch(id)
        this._parseForm(response.data.notification_setting)
      } catch (e) {
        this.$root.$children[0].toast(
          'Erro ao buscar Configuração de Notificação'
        )
        throw e
      } finally {
        this.$scrollTop()
        this.loading = false
      }
    },

    save() {
      this.saveLoading = true

      Api.notificationSettings
        .save(this.$route.params.id, {
          notification_setting: this.form,
        })
        .then(response => {
          this._parseForm(response.data.notification_setting)
          this.$root.$children[0].toast(
            'Configuração de Notificação salva com sucesso'
          )

          this.$router.push({
            name: 'NotificationSettingShow',
            params: { id: this.form.id },
          })
        })
        .catch(error => {
          if (error.response) {
            this.$root.$children[0].toast(
              'Erro ao salvar Configuração de Notificação'
            )
            if (error.response.status != 500) {
              this.errors = error.response.data.errors

              this.$refs.form.validate()
            }
          } else {
            throw error
          }

          // throw error;
        })
        .finally(() => {
          this.$scrollTop()
          this.saveLoading = false
        })
    },
  },
}
</script>
